import type { FirebaseApp } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, signOut, Auth } from 'firebase/auth';
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from 'firebase/remote-config';
import { getFirestore } from 'firebase/firestore';
import type { Timestamp } from 'firebase/firestore';

export let firebaseConfig = {
  apiKey: 'AIzaSyDbSs73qsRinJSMDfyFIa_xXdCS4K05Fro',
  projectId: 'fromm-fan-361704',
  storageBucket: 'fromm-fan-361704.appspot.com',
  appId: '1:693404733592:ios:b06ad9feb99b0cd271c0ed',
};

export type FrommTicket = {
  id: string;
  purchaseId: string;
  originalPurchaseId: string;
  ticketId: string;
  productId: string;
  titleOverseas: {
    ko: string;
    en: string;
  };
  purchaseMethodOverseas: {
    ko: string;
    en: string;
  };
  slot: number;
  startAt: string;
  endAt: string;
  leftDay: number;
  autoRenewal: boolean;
  nextProductId: string | null;
  nextTicketId: string | null;
  nextTicketSlot: number;
  nextTicketTitle: {
    [key: string]: string;
  };
  nextTicketPurchaseMethod: {
    [key: string]: string;
  };
};

export type FrommUserInfo = {
  refreshToken: string;
  accessToken: string;
  expireIn: number;
  level: string;
  pushToken: string;
  fan: {
    id: string;
    email: string;
    nick: string;
    birthday: string | null;
    gender: number;
    countryCode: string;
    backgroundUrl: string;
    profileUrl: string;
    statusMessage: string;
  };
  usingTicket: FrommTicket;
  userInfoVersion: 1;
  cloudfrontInfo: {
    signature: string;
    policy: string;
    publicKey: string;
    domain: string;
  };
};

export type FrommFriend = {
  id: string;
  adminName: string;
  nameOverseas: {
    ko: string;
    en: string;
    ja: string;
  };
  nick: string;
  profileUrl: string;
  profileUrlThumbnail: string;
  backgroundUrl: string;
  defaultBackgroundUrl: string;
  imageUrl: string;
  storeUrl: string;
  statusMessage: string;
  lastLoginAt: string;
  addFriendAt: string;
  goodbyeAt: string | null;
  dDay: number;
  type: string;
  group: {
    id: string;
    name: string;
  }[];
};

export type FrommFriends = {
  friends: FrommFriend[];
  slot: number;
  usingTicket: FrommTicket;
};

export type FrommMessage = {
  id: string;
  starId: string;
  userId: string;
  userType: string;
  createdAt: string;
  updatedAt: string;
  type: string;
  content: string;
  starChatId: string;
  deleted: boolean;
  reported: boolean;
  blocked: boolean;
  code: string;
  fanNick: string;
};

export type FrommFirebaseMessage = {
  NO_ID_FIELD: string;
  starId: string;
  userId: string;
  userType: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  type: string;
  content: string;
  thumbnail: null | string;
  runningTime?: number;
  starChatId: string;
  deleted: boolean;
  reported?: boolean;
  blocked?: boolean;
  code: string;
  hasNick: boolean;
  fanNick?: string;
  existReply?: true;
  existReplyAt?: Timestamp;
};

export class FrommError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class FrommClient {
  base: URL;
  userInfo?: FrommUserInfo;

  app: FirebaseApp;
  _auth?: Auth;
  _auth_getAdditionalHeaders?: () => Promise<Record<string, string>>;

  constructor(base: URL | string = 'https://fromm-cors-00.goranmoomin.dev/') {
    if (typeof base === 'string') {
      base = new URL(base);
    }
    this.base = base;
    this.app = initializeApp(firebaseConfig);
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(
        localStorage.getItem('userInfo')!,
      ) as FrommUserInfo;
    }
  }

  get auth() {
    if (!this._auth) {
      this._auth = getAuth(this.app);
      this._auth_getAdditionalHeaders = (
        (this._auth as any)._getAdditionalHeaders as () => Promise<
          Record<string, string>
        >
      ).bind(this._auth);
      (this._auth as any)._getAdditionalHeaders = async () => {
        let additionalHeaders = await this._auth_getAdditionalHeaders!();
        additionalHeaders['X-Ios-Bundle-Identifier'] =
          'com.knowmerce.fromm.fan';
        return additionalHeaders;
      };
    }
    return this._auth;
  }

  get firestore() {
    return getFirestore(this.app);
  }

  get remoteConfig() {
    return getRemoteConfig(this.app);
  }

  async get<T>(url: URL | string): Promise<T> {
    let response = await fetch(new URL(url, this.base), {
      method: 'GET',
      headers: {
        Authorization: this.userInfo?.accessToken
          ? `Bearer ${this.userInfo.accessToken}`
          : '',
      },
    });
    let json = await response.json();
    if (!json.success) throw new FrommError(json.message);
    return json.data;
  }

  async post<T>(url: URL | string, body: any): Promise<T> {
    let response = await fetch(new URL(url, this.base), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.userInfo?.accessToken
          ? `Bearer ${this.userInfo.accessToken}`
          : '',
      },
      body: JSON.stringify(body),
    });
    let json = await response.json();
    if (!json.success) throw new FrommError(json.message);
    return json.data;
  }

  async refreshToken() {
    let id = localStorage.getItem('id');
    let deviceId = localStorage.getItem('deviceId');
    let refreshToken = localStorage.getItem('refreshToken');
    if (!id || !deviceId || !refreshToken) throw new FrommError();
    this.userInfo = await this.post<FrommUserInfo>('auth/refresh', {
      id,
      deviceId,
      refreshToken,
    });
    await this.signInFirebase();
    localStorage.setItem('refreshToken', this.userInfo.refreshToken);
    localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
  }

  async signIn(
    id: string,
    password: string,
    deviceId: string,
    isAutoLogin = true,
  ) {
    this.userInfo = undefined;
    this.userInfo = await this.post<FrommUserInfo>('auth/signin', {
      id,
      password,
      deviceId,
      isAutoLogin,
    });
    await this.signInFirebase();
    localStorage.setItem('id', id);
    localStorage.setItem('deviceId', deviceId);
    localStorage.setItem('refreshToken', this.userInfo.refreshToken);
    localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
  }

  async signInFirebase() {
    let { accessToken: customToken } = await this.get<{ accessToken: string }>(
      'auth/signin/firebase',
    );
    await signInWithCustomToken(this.auth, customToken);
  }

  async signOut() {
    this.userInfo = undefined;
    localStorage.clear();
    await signOut(this.auth);
  }

  async friends() {
    return await this.get<FrommFriends>('fan/friends');
  }

  async bestFriends() {
    return await this.get<FrommFriend[]>('fan/best-friends');
  }

  async sendChatMessage(friendId: string, content: string, code: string) {
    return await this.post<FrommMessage>(`fan/chat/${friendId}`, {
      content,
      type: 'text',
      code,
    });
  }

  async getForbiddenWords(): Promise<string[]> {
    await fetchAndActivate(this.remoteConfig);
    try {
      return JSON.parse(
        getValue(this.remoteConfig, 'forbidden_word_for_chat').asString(),
      );
    } catch (error) {
      return [];
    }
  }
}

export let frommClient = new FrommClient();

export function getCloudFrontURL(
  url: URL | string,
  client: FrommClient = frommClient,
): URL {
  let { policy, signature, publicKey } = client.userInfo!.cloudfrontInfo;
  try {
    url = new URL(url);
    url.searchParams.append('Policy', policy);
    url.searchParams.append('Signature', signature);
    url.searchParams.append('Key-Pair-Id', publicKey);
    return url;
  } catch (error) {
    console.error(error);
    return new URL('https://placehold.co/300x200');
  }
}
