import { useState } from 'react';
import { Form, redirect, useActionData, useNavigation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FrommError, frommClient } from '~/src/fromm';

import Spinner from '~/src/components/spinner';

export async function loader() {
  return redirect('/');
}

export async function action({ request }: { request: Request }) {
  let formData = await request.formData();
  let email = formData.get('email') as string;
  let password = formData.get('password') as string;
  let lastError: FrommError = undefined!;
  for (let base of bases) {
    try {
      frommClient.base = base;
      await frommClient.signIn(email, password, uuidv4(), false);
      return redirect('/friends');
    } catch (error) {
      if (error instanceof FrommError) {
        if (error.message === '잘못된 인증입니다.') {
          lastError = error;
          continue;
        }
        return error;
      }
      throw error;
    }
  }
  return lastError;
}

export default function Login() {
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let error = useActionData() as FrommError | undefined;
  let { state } = useNavigation();
  let hasPreviousUserInfo = false;
  let isFormDisabled = !hasPreviousUserInfo || state !== 'idle';
  return (
    <main className='min-h-screen flex items-center justify-center'>
      <Form method='post' className='max-w-md w-full flex flex-col gap-8'>
        <h2 className='text-center text-3xl font-extrabold'>
          Sign in to Your Account
        </h2>
        <div className='-space-y-px'>
          <input
            name='email'
            type='email'
            required
            className='relative w-full px-4 py-2 border border-gray-300 rounded-t-lg placeholder-gray-500 focus:border-rose-500 focus:z-10'
            placeholder='Email address'
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={isFormDisabled}
          />
          <input
            name='password'
            type='password'
            required
            className='relative w-full px-4 py-2 border border-gray-300 rounded-b-lg placeholder-gray-500 focus:border-rose-500 focus:z-10'
            placeholder='Password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={isFormDisabled}
          />
        </div>
        {error && <div className='text-rose-500 text-sm'>{error.message}</div>}
        {error && error.message === '잘못된 인증입니다.' && (
          <div className='text-rose-500 text-sm'>
            Fromm 측에서 로그인을 막은 것으로 추정됩니다. 2~5분 내로 복구될
            예정이니 조금만 기다려주세요.
          </div>
        )}

        <button
          type='submit'
          className={`${
            !isFormDisabled ? 'bg-rose-500 hover:bg-rose-600' : 'bg-rose-300'
          } w-full py-2 rounded-lg text-white font-medium`}
          disabled={isFormDisabled}
        >
          {state !== 'idle' ? (
            <Spinner />
          ) : hasPreviousUserInfo ? (
            'Sign in'
          ) : (
            'Fromm 측 요청으로 서비스 이용이 불가합니다'
          )}
        </button>
      </Form>
    </main>
  );
}
