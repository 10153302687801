import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Landing() {
  let [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  let [
    isFrommDescriptionLanguageEnglish,
    setIsFrommDescriptionLanguageEnglish,
  ] = useState(false);
  let hasPreviousUserInfo = false;
  return (
    <main className='min-h-screen flex items-center justify-center'>
      <div className='max-w-md w-full flex flex-col gap-4 text-center items-center'>
        <img
          src={new URL('~/src/resources/fromm.svg', import.meta.url).href}
          alt='Fromm Logo'
          className='h-10'
        />
        <h1 className='text-2xl font-bold'>for Web (Unofficial)</h1>
        <p>An unofficial web client for Fromm, the messaging app</p>
        <Link
          to={hasPreviousUserInfo ? '/friends' : '#'}
          className={`px-4 py-2 rounded-lg text-white font-medium ${
            hasPreviousUserInfo
              ? 'bg-rose-500 hover:bg-rose-600'
              : 'bg-rose-300'
          }`}
        >
          {hasPreviousUserInfo ? '→ Start' : 'Fromm 측 요청으로 서비스 이용이 불가합니다'}
        </Link>
        <p className='text-sm text-gray-400'>
          {!isFrommDescriptionLanguageEnglish
            ? '프롬 측 요청으로, 8월 10일 오후 06:00부터 서비스 이용이 불가합니다.'
            : 'Per Fromm’s request, the service will be unavailable starting from 08/10 18:00 (KST). Additional info can be found here:'}
          <br />
          {isFrommDescriptionLanguageEnglish ||
            '추가 정보는 다음에서 확인 가능합니다: '}
          <a
            className='underline'
            href='https://twitter.com/3somsoc/status/1689543565758861312'
          >
            {!isFrommDescriptionLanguageEnglish
              ? '관련 트윗'
              : 'Relevant tweet'}
          </a>{' '}
          <button
            onClick={() =>
              setIsFrommDescriptionLanguageEnglish(
                !isFrommDescriptionLanguageEnglish,
              )
            }
            className='hover:underline'
          >
            ({isFrommDescriptionLanguageEnglish ? 'KOR' : 'ENG'})
          </button>
        </p>
        <p className='text-xs text-gray-400'>
          Created by <a href='https://twitter.com/3somsoc'>@3somsoc</a>{' '}
          <button
            onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
            className='hover:underline'
          >
            (Why?)
          </button>
        </p>
        {isDescriptionVisible && (
          <p className='text-xs text-gray-400'>
            Because I was frustrated by the lack of a web-version of the Fromm
            app.
            <br /> Bug reports, feature requests through DMs are appreciated.
          </p>
        )}
      </div>
    </main>
  );
}
