import { useState, useEffect, useRef, memo } from 'react';
import { useParams } from 'react-router-dom';

import {
  collection,
  query,
  orderBy,
  limit,
  where,
  or,
} from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire';
import { v4 as uuidv4 } from 'uuid';
import { frommClient, getCloudFrontURL } from '~/src/fromm';
import type { FrommFirebaseMessage } from '~/src/fromm';
import Spinner from '~/src/components/spinner';

function MessageContent({ message }: { message: FrommFirebaseMessage }) {
  if (message.type === 'text') {
    return <p>{message.content}</p>;
  } else if (message.type === 'sound') {
    return <audio controls src={getCloudFrontURL(message.thumbnail!).href} />;
  } else if (message.type === 'image') {
    return (
      <img
        src={getCloudFrontURL(message.content).href}
        className='h-80 object-contain'
      />
    );
  } else if (message.type === 'video') {
    return (
      <video
        controls
        src={getCloudFrontURL(message.content).href}
        className='h-80'
      />
    );
  } else {
    console.error(`${message.type} messages are not yet supported.`);
    return <p>{message.content}</p>;
  }
}

function Message({ message }: { message: FrommFirebaseMessage }) {
  let isStarMessage = message.userType === 'star';
  let createdAtDate = message.createdAt.toDate();
  let formattedTime = createdAtDate.toLocaleTimeString();

  return (
    <div
      className={`flex ${isStarMessage ? 'justify-start' : 'justify-end'} mx-2`}
    >
      {!isStarMessage && (
        <div className='text-xs text-gray-400 me-4 flex self-center'>
          {formattedTime} {message.fanNick ?? ''}
        </div>
      )}
      <div
        className={`px-4 py-2 rounded-2xl ${
          isStarMessage ? 'bg-gray-200 text-black' : 'bg-rose-500 text-white'
        }`}
      >
        <MessageContent message={message} />
      </div>
      {isStarMessage && (
        <div className='text-xs text-gray-400 ms-4 flex self-center'>
          {formattedTime}
        </div>
      )}
    </div>
  );
}

let Messages = memo(function Messages({
  messages,
}: {
  messages: FrommFirebaseMessage[];
}) {
  messages = Array.from(messages).reverse();
  return (
    <div className='flex flex-col gap-2 max-w-xl mx-auto my-2'>
      {messages.map(message => (
        <Message key={message.code} message={message} />
      ))}
    </div>
  );
});

export default function Chat() {
  let { friendId } = useParams() as { friendId: string };
  let [message, setMessage] = useState('');
  let [isDevMode, setIsDevMode] = useState(false);
  let [isMasterMode, setIsMasterMode] = useState(false);
  let [isSending, setIsSending] = useState(false);
  let messageInputRef = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (message === 'politelyrequestingdevmode') {
      setIsDevMode(true);
    } else if (message === 'politelyrequestingmastermode') {
      setIsDevMode(true);
      setIsMasterMode(true);
    }
  }, [message]);

  let chatContainerRef = useRef<HTMLDivElement>(null!);

  let firestore = useFirestore();
  let user = useUser().data;
  let messagesQuery = query(
    collection(firestore, 'root-collection', friendId, 'message'),
    orderBy('createdAt', 'desc'),
    limit(200),
  );
  if (!isMasterMode) {
    messagesQuery = query(
      messagesQuery,
      or(where('userId', '==', user?.uid), where('userType', '==', 'star')),
    );
  }

  let messages = useFirestoreCollectionData(messagesQuery)
    .data as FrommFirebaseMessage[];

  let isSendDisabled =
    isSending ||
    message === '' ||
    (!isDevMode &&
      (message.length > 100 ||
        !messages.slice(0, 3).some(message => message.userType === 'star')));

  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages]);

  async function handleSendMessage(e: React.FormEvent) {
    e.preventDefault();
    for (let forbiddenWord of await frommClient.getForbiddenWords()) {
      let forbiddenWordStartIndex = message.indexOf(forbiddenWord);
      if (forbiddenWordStartIndex !== -1) {
        messageInputRef.current.setSelectionRange(
          forbiddenWordStartIndex,
          forbiddenWordStartIndex + forbiddenWord.length,
        );
        return;
      }
    }
    setIsSending(true);
    await frommClient.sendChatMessage(friendId, message, uuidv4());
    setIsSending(false);
    setMessage('');
  }

  return (
    <div className='overflow-y-auto h-full' ref={chatContainerRef}>
      <Messages messages={messages} />
      <form
        className='flex max-w-xl mx-auto py-2 sticky bottom-0 bg-white'
        onSubmit={handleSendMessage}
      >
        <input
          ref={messageInputRef}
          type='text'
          value={message}
          onChange={e => setMessage(e.target.value)}
          className='flex-1 border border-gray-300 rounded-lg py-2 px-4 ms-2 me-2 focus:border-rose-300'
          placeholder='Type your message here...'
          disabled={isSending}
          autoFocus
        />
        <button
          className={`${
            !isSendDisabled ? 'bg-rose-500 hover:bg-rose-600' : 'bg-rose-300'
          } text-white font-medium me-2 py-2 px-4 rounded-lg`}
          disabled={isSendDisabled}
        >
          {isSending ? <Spinner /> : 'Send'}
        </button>
      </form>
    </div>
  );
}
