import { Suspense, useState, useEffect } from 'react';
import {
  Outlet,
  Link,
  NavLink,
  useLoaderData,
  redirect,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import Modal from 'react-modal';
import { collection, query, orderBy, limit, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { formatDistance } from 'date-fns';

import {
  FrommError,
  frommClient,
  getCloudFrontURL,
  FrommFriend,
  FrommFirebaseMessage,
} from '~/src/fromm';

export async function loader() {
  return redirect('/');
  try {
    if (!frommClient.userInfo) {
      await frommClient.refreshToken();
    }
    let [{ friends }, bestFriends] = await Promise.all([
      frommClient.friends(),
      frommClient.bestFriends(),
    ]);
    return friends.concat(bestFriends);
  } catch (error) {
    if (error instanceof FrommError) {
      throw redirect('/login');
    } else {
      throw error;
    }
  }
}

function useDate(delay: number) {
  let [date, setDate] = useState(new Date());
  useEffect(() => {
    let intervalId = setInterval(() => setDate(new Date()), delay);
    return () => clearInterval(intervalId);
  });
  return date;
}

function Friend({ friend }: { friend: FrommFriend }) {
  let firestore = useFirestore();
  let messageQuery = query(
    collection(firestore, 'root-collection', friend.id, 'message'),
    where('userType', '==', 'star'),
    orderBy('createdAt', 'desc'),
    limit(1),
  );
  let message = useFirestoreCollectionData(messageQuery)
    .data[0] as FrommFirebaseMessage;
  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        new Notification(friend.nick, {
          body: message.content,
          icon: getCloudFrontURL(
            friend.profileUrlThumbnail || friend.profileUrl,
          ).href,
        });
      } else if (Notification.permission !== 'denied') {
        (async () => {
          await Notification.requestPermission();
          new Notification(friend.nick, { body: message.content });
        })();
      }
    }
  }, [message.content]);
  let date = useDate(60 * 1000);
  return (
    <NavLink
      to={friend.id}
      className={({ isActive }) =>
        `${
          isActive ? 'font-medium bg-gray-200' : 'hover:bg-gray-100'
        } px-4 py-3 rounded-e-lg flex gap-2 items-center`
      }
    >
      <img
        src={
          getCloudFrontURL(friend.profileUrlThumbnail || friend.profileUrl).href
        }
        alt={`Profile of ${friend.nick}`}
        className='w-10 h-10 rounded-full object-cover flex-none'
      />
      <div className='flex flex-col overflow-x-hidden'>
        {friend.nick}
        <div className='font-normal text-xs truncate text-elllipsis'>
          {message.content}
        </div>
        <div className='font-normal text-xs text-gray-400'>
          {formatDistance(message.createdAt.toDate(), date)} ago
        </div>
      </div>
    </NavLink>
  );
}

export default function Friends() {
  let friends = useLoaderData() as FrommFriend[];
  let [isModalOpen, setIsModalOpen] = useState(false);
  let [isSidebarOpen, setIsSidebarOpen] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (isSidebarOpen) setIsSidebarOpen(false);
  }, [location]);
  return (
    <main className='flex h-screen h-[100dvh]'>
      <div
        className='text-gray-600 fixed top-0 end-0 p-4 lg:hidden bg-white/25 backdrop-blur-lg rounded-es-lg'
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <svg
          className='w-6 h-6'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          viewBox='0 0 24 24'
          stroke='currentColor'
        >
          <path d='M4 6h16M4 12h16m-7 6h7'></path>
        </svg>
      </div>
      <div
        className={`${
          isSidebarOpen ? 'flex w-full' : 'hidden'
        } lg:w-1/6 lg:flex flex-col overflow-y-auto`}
      >
        <h3 className='px-4 py-4 text-lg font-bold'>
          <Link to='/'>
            <img
              src={new URL('~/src/resources/fromm.svg', import.meta.url).href}
              alt='Fromm Logo'
              className='inline h-6 pb-1 me-1'
            />
            Friends
          </Link>
        </h3>
        {friends.map(friend => (
          <Friend key={friend.id} friend={friend} />
        ))}
        <button
          className='hover:bg-gray-100 px-4 py-3 rounded-se-lg mt-auto text-start'
          onClick={() => setIsModalOpen(true)}
        >
          <img
            src={getCloudFrontURL(frommClient.userInfo!.fan.profileUrl).href}
            alt='User Profile'
            className='inline me-2 w-8 h-8 rounded-full object-cover'
          />
          {frommClient.userInfo!.fan.nick}
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        overlayClassName='fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75'
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div className='flex flex-col gap-4 rounded-lg bg-white text-center p-2'>
          <h3 className='font-bold pt-4'>Sign Out</h3>
          <p className='text-sm text-gray-500 px-4'>
            Are you sure you want to sign out?
          </p>
          <div className='flex gap-2'>
            <button
              onClick={() => setIsModalOpen(false)}
              className='w-full py-2 rounded-lg bg-white hover:bg-gray-100 font-medium'
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                await frommClient.signOut();
                navigate('/');
              }}
              className='w-full py-2 rounded-lg bg-rose-500 hover:bg-rose-600 text-white font-medium'
            >
              Sign Out
            </button>
          </div>
        </div>
      </Modal>
      <div className={isSidebarOpen ? 'hidden' : 'flex-1'}>
        <Suspense fallback={null}>
          <Outlet />
        </Suspense>
      </div>
    </main>
  );
}

export function ChatPlaceholder() {
  return (
    <div className='h-full flex justify-center items-center'>
      <p className='text-lg text-gray-300'>Select any chat from the sidebar</p>
    </div>
  );
}
